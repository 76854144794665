import React from "react";
import MyProjects from "../MyProjects/MyProjects";

const Portfolio = () => {
  return <div id="portfolio">
    <MyProjects></MyProjects>
  </div>;
};

export default Portfolio;
